import { UserTypeEnum } from "../enums/UserType";

// get localStorage items + send to main platform
function redirectToMainPlatform(routerToPath, appProps, appUrl) {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const lang = localStorage.getItem("lang");
  const signedNda = localStorage.getItem("signed_nda");
  const expires = localStorage.getItem("expires");
  const vueApp = true;

  // parsed
  let parsedUser = JSON.parse(user);

  let localData = {
    token,
    lang,
    signedNda,
    expires,
    vueApp,
    routerToPath,
    appProps: {},
  };

  // CASO PRECISE PASSAR ALGUMA PROP PARA APLICAÇÃO REACT, SÓ PREENCHER ESSE OBJETO
  if (appProps) {
    localData.appProps = { ...appProps };
  }

  if (parsedUser.mustRenewPassword) {
    localData.routerToPath = "/auth/changePassword";
  }

  const objectJson = JSON.stringify(localData);
  const objectJsonToBase64 = Buffer.from(objectJson).toString("base64");

  if (parsedUser.mustRenewPassword) {
    localStorage.clear();
    window.location.href = `${process.env.VUE_APP_REACT_AUTH_PLATFORM_BASE_URL}appHandler?form_data=${objectJsonToBase64}`;
    return;
  }

  localStorage.clear();
  window.location.href = `${appUrl}appHandler?form_data=${objectJsonToBase64}`;
}

// banker - react
export function redirectVuePlatform(routerToPath, appBankerProps) {
  const bankerAppUrl = process.env.VUE_APP_REACT_PLATFORM_BASE_URL;

  redirectToMainPlatform(routerToPath, appBankerProps, bankerAppUrl);
}

// manager - react
export function redirectManagerPlatform(routerToPath, appManagerProps) {
  const managerAppUrl = process.env.VUE_APP_REACT_MANAGER_PLATFORM_BASE_URL;

  redirectToMainPlatform(routerToPath, appManagerProps, managerAppUrl);
}

// partner - react
export function redirectPartnerPlatform(routerToPath, appPartnerProps) {
  const partnerAppUrl = process.env.VUE_APP_REACT_PARTNER_PLATFORM_BASE_URL;

  redirectToMainPlatform(routerToPath, appPartnerProps, partnerAppUrl);
}
